import axios from 'axios'
import fontColorContrast from 'font-color-contrast'
import React, { useState, useEffect } from 'react'
import { Col, Form, Row, Spinner, Alert } from 'react-bootstrap'
import { useForm } from "react-hook-form"
import ReactInputMask from 'react-input-mask'
import { isIterableArray } from '../../block-slider/utils'

const TypeForm = ({ data, page_id }) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm()

    const { emails, text_on_success, text_button, text_under, button_color } = data.setting


    const [isLoading, setIsLoading] = useState(false); // загрузка при отправке
    const [isAlertSuccess, setIsAlertSuccess] = useState(false);
    const [isAlertError, setIsAlertError] = useState(false);


    const [uploadedFiles, setUploadedFiles] = useState({}); // храним названия загруженых файлов в инпуты 
    const [fileList, setFileList] = useState({});

    const [itemsData, setItemsData] = useState({
        checkboxs: [],
        inputs: [],
        buttons: []
    });

    useEffect(() => {
        if (data) {
            dataToFormat(data.items)
        }
    }, [data]);


    // форматируем данные. делим поля на чекбоксы, текстовые инпуты и кнопки 
    // каждому инпуту задаю fieldName который потом используется для формирования данных для отправки
    const dataToFormat = (data) => {
        const checkboxItems = data.reduce((acc, item, index) => {
            if (item.type === 1) acc.push({
                ...item,
                fieldName: `checkbox_${index}`,
            })
            return acc
        }, [])
        const inputItems = data.reduce((acc, item, index) => {
            if (item.type > 1 && item.type < 7) acc.push({
                ...item,
                fieldName: `input_${index}`,
            })
            return acc
        }, [])
        const buttonsItems = data.reduce((acc, item, index) => {
            if (item.type > 6) acc.push({
                ...item,
                fieldName: `file_${index}`,
            })
            return acc
        }, [])
        setItemsData({
            checkboxs: checkboxItems,
            inputs: inputItems,
            buttons: buttonsItems
        })

    }

    const onSubmit = (formData) => {
        // console.log(formData)
        const formatter = (arr) => {
            return arr.reduce((acc, item) => {
                const val = {
                    forEmail: item.forEmail,
                    value: formData[item.fieldName]
                }
                acc.push(val)
                return acc
            }, [])
        }

        const formattedCheckboxs = formatter(itemsData.checkboxs)
        const formattedInputs = formatter(itemsData.inputs)
        const formattedButtonts = formatter(itemsData.buttons)

        const sendData = {
            checkboxs: formattedCheckboxs,
            inputs: formattedInputs,
        }

        handleSend(sendData)
    }


    const handleSend = (sendData) => {
        const formData = new FormData()

        formData.append('data', JSON.stringify(sendData))
        formData.append('emails', JSON.stringify(emails))
        formData.append('files[]', fileList)
        formData.append('page_id', page_id)

        setIsLoading(true)

        axios
            .post(`${process.env.REACT_APP_API_BASE}/pages/send-email`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if (response?.data && response.data?.res) {
                    if (response.data.res === 'Success')
                        setIsAlertSuccess(true)
                    if (response.data.res === 'Error')
                        setIsAlertError(true)
                }
                reset()
            })
            .catch((err) => {
                console.log(err)
                setIsAlertError(true)
            })
            .finally(() => {
                setIsLoading(false)
            });
    }



    const RenderFormItems = ({ items }) => (
        items.map((item, key) => {
            switch (item.type) {
                case 1: return <CheckboxItem key={key} item={item} />
                case 2: return TextCol(item)
                case 3: return TextCol(item)
                case 4: return PhoneCol(item)
                case 5: return EmailCol(item)
                case 6: return CommentCol(item)
                case 7: return <FileInput
                    key={key}
                    item={item}
                    uploadedFiles={uploadedFiles}
                    fileList={fileList}
                    setFileList={setFileList}
                    setUploadedFiles={setUploadedFiles}
                />
                case 8: return <FileDownload key={key} item={item} />

                default: return '';
            }
        })
    )

    const CheckboxItem = ({ item }) => (
        <Col className='mb-2'>
            <Form.Check
                type="checkbox"
                label={item.text}
                id={item.fieldName}
                {...register(item.fieldName)}
            />
        </Col>
    )
    const TextCol = (item) => (
        <Col md={item.type === 2 ? 6 : 12} className='mb-2' key={item.fieldName}>
            <Form.Control
                placeholder={item.text}
                type="text"
                {...register(item.fieldName, { required: !!item.required })}
            />
            {
                errors[item.fieldName] &&
                <Form.Control.Feedback type="invalid" className='d-block'>
                    Обязательно к заполнению
                </Form.Control.Feedback>
            }
        </Col>
    )
    const PhoneCol = (item) => (
        <Col md={6} className='mb-2' key={item.fieldName}>
            <Form.Control
                as={ReactInputMask}
                mask="+7(999)-999-9999"
                type="text"
                placeholder={item.text || 'Телефон'}
                defaultValue={'+7(949)'}
                {...register(item.fieldName, { pattern: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/g })}
            />
            {
                errors[item.fieldName]?.type === 'pattern' &&
                <Form.Control.Feedback type="invalid" className='d-block'>
                    Телефон заполнен некорректно
                </Form.Control.Feedback>
            }
        </Col>
    )
    const EmailCol = (item) => (
        <Col md={6} className='mb-2' key={item.fieldName}>
            <Form.Control
                placeholder={item.text || 'E-mail'}
                type="email"
                {...register(item.fieldName, { required: true })}
            />
            {
                errors[item.fieldName] &&
                <Form.Control.Feedback type="invalid" className='d-block'>
                    E-mail обязателен к заполнению
                </Form.Control.Feedback>
            }
        </Col>
    )
    const CommentCol = (item) => (
        <Col className='mb-2' key={item.fieldName}>
            <Form.Control
                placeholder={item.text || 'Комментарий'}
                rows={3}
                as="textarea"
                {...register(item.fieldName)}
            />
        </Col>
    )

    const FileInput = ({ item }) => (
        <div className='mb-2'>

            <div className='btn-add-file'>
                <label htmlFor={item.fieldName}>
                    {
                        item.icon &&
                        <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + item.icon} alt='' />
                    }
                    {
                        uploadedFiles?.[item.fieldName] ?
                            uploadedFiles?.[item.fieldName]
                            :
                            item.text

                    }
                </label>
                {
                    uploadedFiles?.[item.fieldName] ?
                        <span className='ml-3 text-danger' onClick={() => {
                            setFileList(prev => ({
                                ...prev,
                                [item.fieldName]: null
                            }))
                            setUploadedFiles(prev => ({
                                ...prev,
                                [item.fieldName]: null
                            }))
                        }}>
                            Удалить
                        </span>
                        :
                        ''
                }
                <Form.Control
                    type='file'
                    id={item.fieldName}
                    accept='.doc,.docx,.txt.,image/*,.pdf'
                    onChange={e => {
                        e.target.files.forEach(el => {
                            setFileList(prev => ({
                                ...prev,
                                [item.fieldName]: el
                            }))
                        })
                        e.persist()
                        setValue(item.fieldName, e.currentTarget?.files)
                        setUploadedFiles(prev => ({
                            ...prev,
                            [item.fieldName]: e.target?.files?.[0]?.name
                        }))
                    }}
                />
            </div>
        </div>
    )
    const FileDownload = ({ item }) => (
        <div className='mb-2'>
            <div className='btn-download'>
                <a target='_blank' href={process.env.REACT_APP_BACKEND + item.file}>
                    {
                        item.icon &&
                        <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/blocks/' + item.icon} alt='' />
                    }
                    {item.text}
                </a>
            </div>
        </div>
    )

    return (
        <div className='buildedPage-type_8' >

            {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}


            <form onSubmit={handleSubmit(onSubmit)}>

                <div className='buildedPage-type_8-form'>
                    {
                        isAlertSuccess &&
                        <Alert variant={'success'} onClose={() => setIsAlertSuccess(false)} dismissible>
                            <div dangerouslySetInnerHTML={{ __html: text_on_success }} />
                        </Alert>
                    }
                    {
                        isAlertError &&
                        <Alert variant={'danger'} onClose={() => setIsAlertError(false)} dismissible>
                            <Alert.Heading style={{ fontSize: '1.15rem' }}>Ошибка при отправке</Alert.Heading>
                            <p className='mb-0'>Что-то пошло не так</p>
                        </Alert>
                    }
                    <Row>
                        {
                            isIterableArray(itemsData.checkboxs) &&
                            <RenderFormItems items={itemsData.checkboxs} />
                        }
                    </Row>
                    <Row>
                        {
                            isIterableArray(itemsData.inputs) &&
                            <RenderFormItems items={itemsData.inputs} />
                        }
                    </Row>

                    <Row className='mt-3'>
                        <Col md={6} lg={7} xxl={8}>
                            {
                                isIterableArray(itemsData.buttons) &&
                                <RenderFormItems items={itemsData.buttons} />
                            }
                        </Col>
                        <Col md={6} lg={5} xxl={4}>
                            <button
                                className='btn btn-submit'
                                type='submit'
                                style={{
                                    backgroundColor: button_color,
                                    color: fontColorContrast(button_color)
                                }}
                            >
                                {
                                    isLoading ?
                                        <Spinner animation='border' />
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: text_button }}></div>
                                }
                            </button>

                            {
                                text_under &&
                                <div className='text_under mt-3' dangerouslySetInnerHTML={{ __html: text_under }} />
                            }
                        </Col>
                    </Row>
                </div>

            </form>
        </div >
    )
}

export default TypeForm
