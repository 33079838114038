
// React 
import React, { Component } from 'react';
import { Button, Container, Row, Col, Form, FormLabel, Alert, Tabs, Tab } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import Select from 'react-select'
// import InputNumber from 'rc-input-number';
import CartCtx from '../CartCtx'
import axios from 'axios'
import CheckoutCartModal from './CheckoutCartModal';
import CheckoutCompleteModal from './CheckoutCompleteModal';
import PrivPolicyModal from './PrivPolicyModal';
import BlackListModal from './BlackListModal';
//SCSS
import "../../scss/components/pages/checkout/checkout.scss"
import CartItem from '../cart/CartItem';
import { isIterableArray } from '../block-slider/utils';
import AlertCart from '../cart/AlertCart';

// const MIN_PRICE_FOR_ORDER = 500

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order_number: 0,
            toggleRadio: false,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            phones: [],
            delivery: '1',
            payment: '1',
            comment_user: '',
            order_info: [],
            order_summ: 0,
            city: '',
            address: '',
            home: '',
            flat: '',
            id_center: '1',
            order_numb: 0,
            companyName: '',
            companyInn: '',
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                city: '',
                address: '',
                home: ''
            },
            valid: false,
            submitTry: false,
            confirmed: false,
            checkPhone: true,
            userData: null,
            cities: [],
            texts: {},
            products: [],
            isLoading: true,
            tcList: []
        };

        this.toggleRadioState = this.toggleRadioState.bind(this);

        this.changeInput = this.changeInput.bind(this);
        this.selectCity = this.selectCity.bind(this);
        this.selectPhone = this.selectPhone.bind(this);
        this.selectCenter = this.selectCenter.bind(this);
        this.submitOrder = this.submitOrder.bind(this);

        this.ppConfirm = this.ppConfirm.bind(this)
        this.getProducts = this.getProducts.bind(this)
    }

    changeInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    selectPhone(e) {
        this.setState({
            phone: e.target.value
        })

    }
    selectCity(e) {
        this.setState({
            delivery: e.target.value
        })

    }
    selectCenter(e) {
        this.setState({
            id_center: e.target.value
        })
    }

    ppConfirm(e) {
        this.setState({
            confirmed: e.target.checked
        })
    }

    submitOrder() {


        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let errors = this.state.errors;
        //const validEmailRegex = RegExp(/^(([^<>()[].,;:\s@"]+(\.[^<>()[].,;:\s@"]+)*)|(".+"))@(([^<>()[].,;:\s@"]+\.)+[^<>()[].,;:\s@"]{2,})$/i);

        errors.firstName =
            this.state.firstName.length < 2
                ? 'Поле "Имя" обязательно для заполнения'
                : '';

        errors.lastName =
            this.state.lastName.length < 2
                ? 'Поле "Фамилия" обязательно для заполнения'
                : '';

        /*errors.email =
            // validEmailRegex.test(this.state.email) 
                ? 'Поле "E-Mail" заполнено неверно'
                : '';
        */
        errors.phone =
            this.state.phone.length < 7
                ? 'Поле "Телефон" обязательно для заполнения'
                : '';

        if (this.state.delivery == 99) {
            errors.city =
                this.state.city.length < 2
                    ? 'Поле "Город" обязательно для заполнения'
                    : '';

            errors.address =
                this.state.address.length < 2
                    ? 'Поле "Адрес" обязательно для заполнения'
                    : '';

            errors.home =
                this.state.home.length < 1
                    ? 'Поле "Дом" обязательно для заполнения'
                    : '';
        }

        let errorCount = 0;
        for (let err in errors) {
            if (errors.hasOwnProperty(err)) {
                if (errors[err].length > 0) {
                    errorCount++;
                }
            }
        }

        this.setState({ errors: errors, submitTry: true })

        if (errorCount === 0) {
            const postData = {
                order_numb: Math.floor(Date.now() / 1000),
                name: this.state.firstName + ' ' + this.state.lastName,
                city: (this.state.delivery === '5') ? this.state.city : '',
                address: (this.state.delivery === '5') ? this.state.address : '',
                home: (this.state.delivery === '5') ? this.state.home : '',
                flat: (this.state.delivery === '5') ? this.state.flat : '',
                phone: this.state.phone,
                email: this.state.email,
                order_info: this.context.cart,
                order_summ: this.context.getTotalSumm(this.state.products),
                delivery: this.state.delivery,
                payment: this.state.payment,
                id_center: this.state.id_center,
                comment_user: this.state.comment_user,
                company_inn: this.state.companyInn,
                company_name: this.state.companyName,
                checkPhone: true,
            }
            let postURL = `${process.env.REACT_APP_API_BASE}/addorder`
            let postConfig = {}
            if (localStorage.getItem('galaxy-token')) {
                postURL += `?jwt=${localStorage.getItem('galaxy-token')}`
                postConfig = {
                    headers: { Authorization: "Bearer " + localStorage.getItem('galaxy-token') }
                }
            }
            console.log(postData)
            // axios.post(postURL, postData, postConfig)
            //     .then(response => {
            //         if (response.status === 200) {
            //             setTimeout(() => {
            //                 this.setState({ valid: true, order_number: postData.order_numb })
            //             }, 400)
            //         }
            //     })
            //     .catch(err => {
            //         console.error(err);
            //         this.setState({ checkPhone: false })
            //     })
        }

    }
    toggleRadioState(e) {
        this.setState({
            toggleRadio: !this.state.toggleRadio,
            delivery: e.target.value
        })
        let parent;
        if (e.target.type === 'input') parent = e.target.parentNode
        else parent = e.target.parentNode.parentNode;
        if (document.querySelector(".checkout-delivery__item.open") !== null) {
            document.querySelector(".checkout-delivery__item.open").classList.remove("open");
        }
        parent.classList.add('open');
    }
    getProducts() {
        this.setState({
            isLoading: true
        })
        const ids = this.context.cart.map(i => i.id)
        axios.post(`${process.env.REACT_APP_API_BASE}/products/products-for-cart`, { ids })
            .then(response => {
                if (isIterableArray(response.data.data)) {
                    const res = response.data.data.map(prod => ({
                        ...prod,
                        count_cart: this.context.cart.find(i => i.id == prod.id).count_cart
                    }))
                    this.setState({
                        products: res
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }
    componentDidMount() {

        this.getProducts()

        axios.get(`${process.env.REACT_APP_API_BASE}/pages/cart-text`)
            .then(response => {
                this.setState({
                    texts: response.data
                })
            })
            .catch(err => {
                console.error(err)
            })
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/get-cities`).then(response => {
            this.setState({
                cities: response.data.data
            })
        })
        if (localStorage.getItem('galaxy-token')) {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    userData: response.data.data.info
                }, () => {
                    if (this.state.userData.name) this.setState({ firstName: this.state.userData.name })
                    if (this.state.userData.email) this.setState({ email: this.state.userData.email })
                    // if (this.state.userData.middle_name) this.setState({ middleName: this.state.userData.middle_name })
                    if (this.state.userData.surname) this.setState({ lastName: this.state.userData.surname })
                    if (this.state.userData.phones.length > 0) this.setState({ phone: this.state.userData.phones[0] })
                    if (this.state.userData.city) this.setState({ city: this.state.userData.city })
                    if (this.state.userData.street) this.setState({ address: this.state.userData.street })
                    if (this.state.userData.home) this.setState({ home: this.state.userData.home })
                    if (this.state.userData.flat) this.setState({ flat: this.state.userData.flat })
                    if (this.state.userData.company_inn) this.setState({ companyInn: this.state.userData.company_inn })
                    if (this.state.userData.company_name) this.setState({ companyName: this.state.userData.company_name })
                    if (this.state.userData.flat) this.setState({ flat: this.state.userData.flat })
                    if (this.state.userData.phones.length > 1) {
                        this.setState({
                            phones: this.state.userData.phones
                        })
                    }
                    // console.log(this.state.companyName)
                })
            }).catch(err => {
                console.error(err)
            })

        }
        if (document.querySelector("label[for='delivery1']") !== null) document.querySelector("label[for='delivery1']").click();
        if (document.querySelector("label[for='payment1']") !== null) document.querySelector("label[for='payment1']").click();
    }
    componentDidUpdate() {
        if (isIterableArray(this.context?.tcData?.list) && !isIterableArray(this.state.tcList)) {
            const list = this.context.tcData.list.reduce((acc, item) => {
                item.ts_list.map(tc => {
                    acc.push({
                        tcId: tc.id,
                        tcName: tc.address
                    })
                })
                return acc
            }, [])
            console.log(list)
            this.setState({
                tcList: list
            })
        }
    }
    static contextType = CartCtx;
    render() {
        return (
            <main className='page-checkout'>
                {
                    this.state.isLoading ?
                        <Container>
                            <h3>Оформление заказа</h3>

                            <p>Загрузка...</p>
                        </Container>
                        :
                        <>
                            {
                                this.context.cart.length === 0 &&
                                <Container>
                                    <div className="cart-empty">
                                        <h3>Корзина пуста</h3>

                                        <Link to="/" className="btn btn-primary">Вернуться на главную</Link>
                                    </div>
                                </Container>
                            }
                            {
                                this.context.cart.length > 0 &&
                                <Container>
                                    <h3>Оформление заказа</h3>
                                    <Row>
                                        <Col xs={12} lg={8}>
                                            {this.context.getTotalSumm(this.state.products) < this.state.texts?.min_price &&
                                                <Alert variant="danger">
                                                    <Alert.Heading >Внимание!</Alert.Heading>
                                                    <p>Сумма минимального заказа {this.state.texts?.min_price} рублей.</p>
                                                </Alert>
                                            }
                                            <div className="checkout-form">
                                                <div className="checkout-form__step">
                                                    <div className="checkout-form__step__number">
                                                        <span>1</span>
                                                    </div>
                                                    <div className="checkout-form__step__wrap">
                                                        <h5>Контактные данные</h5>
                                                        <Tabs defaultActiveKey="contact01" transition={false} id="contactTabs">
                                                            <Tab eventKey="contact01" title="Я новый покупатель">
                                                                <div className="checkout-login">
                                                                    <Form.Group controlId="logFName" className="half-width">
                                                                        <Form.Label>Имя</Form.Label>
                                                                        <Form.Control type="text" name="firstName" value={this.state.firstName} onChange={this.changeInput} />
                                                                    </Form.Group>
                                                                    <Form.Group controlId="logLName" className="half-width">
                                                                        <Form.Label className='pl-3 pr-4'>Фамилия</Form.Label>
                                                                        <Form.Control type="text" name="lastName" value={this.state.lastName} onChange={this.changeInput} />
                                                                    </Form.Group>
                                                                    <Form.Group controlId="logPhone" className='w-100'>
                                                                        <Form.Label>Телефон</Form.Label>
                                                                        <InputMask mask="+7(999)-999-9999" name="phone" value={this.state.phone} onChange={this.changeInput} className="form-control" />
                                                                    </Form.Group>
                                                                    <Form.Group controlId="logEmail" className='w-100'>
                                                                        <Form.Label className="logEmail">E-mail</Form.Label>
                                                                        <Form.Control type="email" name="email" value={this.state.email} onChange={this.changeInput} />
                                                                    </Form.Group>
                                                                    <Form.Group>&nbsp;</Form.Group>
                                                                    {
                                                                        this.state.phones.length > 1 &&
                                                                        <Form.Group>
                                                                            <Form.Control as="select" onChange={this.selectPhone} value={this.state.phone} style={{ width: '75%', marginLeft: '27%' }}>
                                                                                {this.state.phones.map((phone, index) => {
                                                                                    return (
                                                                                        <option value={phone} key={index}>
                                                                                            {phone}
                                                                                        </option>
                                                                                    )
                                                                                })
                                                                                }
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    }

                                                                    {/* <h5>Юридическое лицо</h5>
                                                                        <div className="w-100"></div>
                                                                        <div className="checkout-login">
                                                                            <Form.Group controlId="logFName" className="half-width">
                                                                                <Form.Label style={{ minWidth: 85 }}>Компания</Form.Label>
                                                                                <Form.Control type="text" name="companyName" value={this.state.companyName} onChange={this.changeInput} />
                                                                            </Form.Group>
                                                                            <Form.Group controlId="logLName" className="half-width">
                                                                                <Form.Label style={{ minWidth: 85 }}>Код ЕГРПОУ</Form.Label>
                                                                                <InputMask
                                                                                    mask="99999999"
                                                                                    name="companyInn"
                                                                                    value={this.state.companyInn}
                                                                                    onChange={this.changeInput}
                                                                                    className="form-control" />
                                                                            </Form.Group>
                                                                        </div> */}
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="contact02" title="Я постоянный клиент">
                                                                <div className="checkout-login">
                                                                    <Form.Group controlId="logPhone" className='w-100'>
                                                                        <Form.Label>Телефон</Form.Label>
                                                                        <InputMask mask="+7(999)-999-9999" name="phone" value={this.state.phone} onChange={this.changeInput} className="form-control" />
                                                                    </Form.Group>
                                                                    <Form.Group controlId="logEmail" className='w-100'>
                                                                        <Form.Label className="logEmail">E-mail</Form.Label>
                                                                        <Form.Control type="email" name="email" value={this.state.email} onChange={this.changeInput} />
                                                                    </Form.Group>
                                                                    <Button
                                                                        className='w-100 my-3'
                                                                        variant="primary"
                                                                        disabled={true}
                                                                    >
                                                                        Войти
                                                                    </Button>


                                                                    {/* <h5>Юридическое лицо</h5>
                                                                        <div className="w-100"></div>
                                                                        <div className="checkout-login">
                                                                            <Form.Group controlId="logFName" className="half-width">
                                                                                <Form.Label style={{ minWidth: 85 }}>Компания</Form.Label>
                                                                                <Form.Control type="text" name="companyName" value={this.state.companyName} onChange={this.changeInput} />
                                                                            </Form.Group>
                                                                            <Form.Group controlId="logLName" className="half-width">
                                                                                <Form.Label style={{ minWidth: 85 }}>Код ЕГРПОУ</Form.Label>
                                                                                <InputMask
                                                                                    mask="99999999"
                                                                                    name="companyInn"
                                                                                    value={this.state.companyInn}
                                                                                    onChange={this.changeInput}
                                                                                    className="form-control" />
                                                                            </Form.Group>
                                                                        </div> */}
                                                                </div>
                                                            </Tab>
                                                        </Tabs>

                                                        {
                                                            this.state.texts?.text2?.text &&
                                                            <AlertCart data={this.state.texts?.text2} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="checkout-form__step">
                                                    <div className="checkout-form__step__number">
                                                        <span>2</span>
                                                    </div>
                                                    <div className="checkout-form__step__wrap">
                                                        <h5>Доставка</h5>
                                                        <div className="checkout-delivery">
                                                            <Form.Group className="checkout-delivery__item">
                                                                <Form.Check type="radio" id="delivery1" name="delivery" label='Самовывоз из ТЦ "Галактика"'
                                                                    onChange={this.toggleRadioState} value="1"
                                                                />
                                                                <div className="checkout-delivery__dropdown">
                                                                    <div className="checkout-delivery__dropdown__wrap">
                                                                        <div className="row-city">
                                                                            <Form.Group>
                                                                                <Form.Control as="select" onChange={this.selectCity} value={this.state.delivery}>
                                                                                    {
                                                                                        isIterableArray(this.state.tcList) &&
                                                                                        this.state.tcList.map(tc => (
                                                                                            <option key={tc.tcId} value={tc.tcId}>{tc.tcName}</option>
                                                                                        ))
                                                                                    }
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                            <div className="select_info">

                                                                                {/* {this.state.delivery === '1' &&
                                                                                    <p>г. Макеевка, просп. 250-летия Донбасса, 74</p>
                                                                                }
                                                                                {this.state.delivery === '2' &&
                                                                                    <p>г. Донецк, ул. Шутова, 37</p>
                                                                                }
                                                                                {this.state.delivery === '3' &&
                                                                                    <p>г. Горловка, ул. Маршала Жукова, 7</p>
                                                                                }
                                                                                {this.state.delivery === '4' &&
                                                                                    <p>г. Донецк ЖД, ул. Соколиная, 38</p>
                                                                                } */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                            <Form.Group className="checkout-delivery__item">
                                                                <Form.Check type="radio" id="delivery2" name="delivery" label='Доставка ТЦ "Галактика"'
                                                                    onChange={this.toggleRadioState} value="99"
                                                                />
                                                                <div className="checkout-delivery__dropdown">
                                                                    <div className="checkout-delivery__dropdown__wrap">
                                                                        <p className="subtitle">Расчет стоимости доставки проводится дополнительно</p>
                                                                        <FormLabel className="req">Укажите адрес доставки</FormLabel>
                                                                        <Form.Group className="row-address">
                                                                            {/* <Form.Control type="text" name="city" value={this.state.city} onChange={this.changeInput} placeholder="Город*" className="address_city" /> */}
                                                                            {this.state.cities?.length > 0 &&
                                                                                <Select
                                                                                    placeholder={'Населённый пункт'}
                                                                                    options={this.state.cities.map(c => ({ value: c, label: c }))}
                                                                                    onChange={(e) => { this.setState({ city: e.value }) }}
                                                                                    value={this.state.cities.map(c => ({ value: c, label: c })).find(huylo => huylo.value === this.state.city)}
                                                                                />
                                                                            }
                                                                            <Form.Control type="text" name="address" value={this.state.address} onChange={this.changeInput} placeholder="Микрорайон / улица / проспект / бульвар *" className="address_str" />
                                                                            <Form.Control type="text" name="home" value={this.state.home} onChange={this.changeInput} placeholder="Дом № *" className="address_dom" />
                                                                            <Form.Control type="text" name="flat" value={this.state.flat} onChange={this.changeInput} placeholder="Кв. №" className="address_kv" />
                                                                        </Form.Group>
                                                                        <FormLabel className="req city">Выберите ближайший ТЦ</FormLabel>
                                                                        <div className="row-city">
                                                                            <Form.Group>
                                                                                <Form.Control as="select" onChange={this.selectCenter} value={this.state.id_center}>
                                                                                    {
                                                                                        isIterableArray(this.state.tcList) &&
                                                                                        this.state.tcList.map(tc => (
                                                                                            <option key={tc.tcId} value={tc.tcId}>{tc.tcName}</option>
                                                                                        ))
                                                                                    }
                                                                                    {/* <option value="1">ТЦ 'Галактика' №1</option>
                                                                                    <option value="2">ТЦ 'Галактика' №2</option>
                                                                                    <option value="3">ТЦ 'Галактика' №3</option>
                                                                                    <option value="4">ТЦ 'Галактика' №4</option> */}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                            {/* <div className="select_info">
                                                                                {this.state.id_center === '1' &&
                                                                                    <p>г. Макеевка, просп. 250-летия Донбасса, 74</p>
                                                                                }
                                                                                {this.state.id_center === '2' &&
                                                                                    <p>г. Донецк, ул. Шутова, 37</p>
                                                                                }
                                                                                {this.state.id_center === '3' &&
                                                                                    <p>г. Горловка, ул. Маршала Жукова, 7</p>
                                                                                }
                                                                                {this.state.id_center === '4' &&
                                                                                    <p>г. Донецк ЖД, ул. Соколиная, 38</p>
                                                                                }
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                            {/* <Form.Group className="checkout-delivery__item">
                                                        <Form.Check type="radio" id="delivery3" name="delivery" label='Курьером "Наша почта"'
                                                            onChange={this.toggleRadioState} value="6"
                                                        />
                                                        <div className="checkout-delivery__dropdown">
                                                            <div className="checkout-delivery__dropdown__wrap">
                                                                <FormLabel className="req">Укажите адресс доставки</FormLabel>
                                                                <Form.Group className="row-address">
                                                                    <Form.Control type="text" name="city" value={this.state.city} onChange={this.changeInput} placeholder="Город*" className="address_city" />
                                                                    <Form.Control type="text" name="address" value={this.state.address} onChange={this.changeInput} placeholder="Микрорайон / улица / проспект / бульвар *" className="address_str" />
                                                                    <Form.Control type="text" name="home" value={this.state.home} onChange={this.changeInput} placeholder="Дом № *" className="address_dom" />
                                                                    <Form.Control type="text" name="flat" value={this.state.flat} onChange={this.changeInput} placeholder="Кв. №" className="address_kv" />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="checkout-delivery__item">
                                                        <Form.Check type="radio" id="delivery4" name="delivery" label="Курьером 'Почта Донбасса'"
                                                            onChange={this.toggleRadioState} value="7"
                                                        />
                                                        <div className="checkout-delivery__dropdown">
                                                            <div className="checkout-delivery__dropdown__wrap">
                                                                <FormLabel className="req">Укажите адресс доставки</FormLabel>
                                                                <Form.Group className="row-address">
                                                                    <Form.Control type="text" name="city" value={this.state.city} onChange={this.changeInput} placeholder="Город*" className="address_city" />
                                                                    <Form.Control type="text" name="address" value={this.state.address} onChange={this.changeInput} placeholder="Микрорайон / улица / проспект / бульвар *" className="address_str" />
                                                                    <Form.Control type="text" name="home" value={this.state.home} onChange={this.changeInput} placeholder="Дом № *" className="address_dom" />
                                                                    <Form.Control type="text" name="flat" value={this.state.flat} onChange={this.changeInput} placeholder="Кв. №" className="address_kv" />
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </Form.Group> */}
                                                        </div>
                                                        {
                                                            this.state.texts?.text3?.text &&
                                                            <AlertCart data={this.state.texts?.text3} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="checkout-form__step">
                                                    <div className="checkout-form__step__number">
                                                        <span>3</span>
                                                    </div>
                                                    <div className="checkout-form__step__wrap">
                                                        <h5>Оплата</h5>
                                                        <div className="checkout-payment">
                                                            <Form.Group className="checkout-payment__item">
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="payment1"
                                                                    name="payment"
                                                                    value="1"
                                                                    onChange={this.changeInput}
                                                                    label='Наличный расчёт' />
                                                            </Form.Group>
                                                            <Form.Group className="checkout-payment__item">
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="payment2"
                                                                    name="payment"
                                                                    value="2"
                                                                    onChange={this.changeInput}
                                                                    label='Безналичный расчёт'
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="checkout-comment">
                                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                                <Form.Label>Дополнительная информация к заказу </Form.Label>
                                                                <Form.Control as="textarea" rows="3" onChange={this.changeInput} name="comment_user" value={this.state.comment_user} placeholder="Комментарий" />
                                                            </Form.Group>
                                                        </div>
                                                        {
                                                            this.state.texts?.text4?.text &&
                                                            <AlertCart data={this.state.texts?.text4} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            <div className="checkout-total">
                                                <div className="checkout-total__wrap">
                                                    <h5>Состав заказа</h5>
                                                    <div className="cart-items">
                                                        <div className="cart-items__wrap">
                                                            {this.state.products.map((product, index) => (
                                                                <CartItem key={index} product={product} index={index} isControl={this.props.isControl} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="checkout-total__items">
                                                        {/* {this.context.cart.map((product, index) => (
                                                    <div className="checkout-total__item" key={index}>
                                                        <div className="products-image">
                                                            <Link to={`/product/${product.name_slug}`} className="products-image__link">
                                                                <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.img} alt={("Изображение товара " + product.name)} />
                                                            </Link>
                                                        </div>
                                                        <div className="products-info">
                                                            <Link to={`/product/${product.name_slug}`} className="products-title">{product.name}</Link>
                                                            <div className="products-sum">
                                                                <p className="i-rub">{parseFloat((product.price / product.count).toFixed(2)).toLocaleString('ru')}</p>
                                                                <p className="items-total">{product.count} шт.</p>
                                                                <p className="price-total i-rub">{parseFloat((product.price * 1).toFixed(2)).toLocaleString('ru')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))} */}
                                                    </div>
                                                    <div className="checkout-total__info">
                                                        <div className="info">
                                                            <p>Товаров на сумму:</p>
                                                            <span className="i-rub">{parseFloat((this.context.getTotalSumm(this.state.products) * 1).toFixed(2)).toLocaleString('ru')}</span>
                                                        </div>
                                                        <div className="info">

                                                            {this.state.delivery === '1' &&
                                                                <>
                                                                    <p>Самовывоз</p>
                                                                    <span>
                                                                        {
                                                                            this.state.tcList.find(tc => tc.tcId === this.state.delivery)
                                                                        }
                                                                    </span>
                                                                </>
                                                            }
                                                            {this.state.delivery === '99' &&
                                                                <>
                                                                    <p>Доставка</p>
                                                                    <span>Курьером 'Галактика'</span>
                                                                </>
                                                            }
                                                        </div>

                                                        <div className="info">
                                                            <p>Оплата</p>
                                                            {this.state.payment === '1' &&
                                                                <span>Наличный расчёт</span>
                                                            }
                                                            {this.state.payment === '2' &&
                                                                <span>Безналичный расчёт</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="checkout-total__footer">

                                                        <div className="info">
                                                            <p>Итого к оплате:</p>
                                                            <span className="i-rub">{this.context.getTotalSumm(this.state.products).toLocaleString('ru')}</span>
                                                        </div>
                                                        <Button variant="primary" disabled={!this.state.confirmed || this.context.getTotalSumm(this.state.products) < this.state.texts?.min_price} onClick={this.submitOrder}>Подтвердить заказ</Button>
                                                        {this.state.valid &&
                                                            <CheckoutCompleteModal num={this.state.order_number}
                                                                cName={this.state.firstName + ' ' + this.state.lastName}
                                                                cPhone={this.state.phone}
                                                                cEmail={this.state.email}
                                                                cAddress={this.state.city + ', ' + this.state.address + ', д.' + this.state.home + ', кв.' + this.state.flat}
                                                                del={this.state.delivery}
                                                                paym={this.state.payment}
                                                                comment={this.state.comment_user}
                                                                companyName={this.state.companyName}
                                                                companyInn={this.state.companyInn}
                                                            />
                                                        }

                                                        {/* <Button onClick={this.submitOrder} variant="primary">Подтвердить заказ</Button> */}
                                                        {!this.state.checkPhone &&
                                                            <BlackListModal />
                                                        }
                                                        <CheckoutCartModal />
                                                        <PrivPolicyModal ppConfirm={this.ppConfirm} />
                                                        <div style={{
                                                            color: '#e04542'
                                                        }}>
                                                            {this.state.submitTry && !this.state.valid &&
                                                                Object.keys(this.state.errors).map((err, i) => {

                                                                    return (
                                                                        <p key={i}>
                                                                            {this.state.errors[err]}
                                                                        </p>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                        </>
                }
            </main >
        )
    }
}

export default Checkout;
