import React, { useContext, useState } from 'react'
import CartCtx from '../CartCtx'
import CartItem from './CartItem'
import { isIterableArray } from '../block-slider/utils'
import { Alert, Button, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { Slide } from '@progress/kendo-react-animation'
import SliderWrapper from '../block-slider/SliderWrapper'
import SliderFetchWrapper from '../block-slider/SliderFetchWrapper'
import axios from 'axios'
import { useEffect } from 'react'
import AlertCart from './AlertCart'

const PageCart = () => {

    const { cart, getTotalCount, getTotalSumm, getTotalWithoutSale } = useContext(CartCtx)

    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([])
    const [textAlert, setTextAlert] = useState(null);

    useEffect(() => {
        if (isIterableArray(cart)) getProducts()
        else setIsLoading(false)
        getTexts()
    }, [cart])


    const getProducts = () => {
        setIsLoading(true)
        const ids = cart.map(i => i.id)
        axios.post(`${process.env.REACT_APP_API_BASE}/products/products-for-cart`, { ids })
            .then(response => {
                console.log(response)
                if (isIterableArray(response.data.data)) {
                    const res = response.data.data.map(prod => ({
                        ...prod,
                        count_cart: cart.find(i => i.id == prod.id).count_cart
                    }))
                    setProducts(res)
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getTexts = () => {
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/cart-text`)
            .then(response => {
                if (response.data?.text1) setTextAlert(response.data?.text1)
            })
            .catch(err => {
                console.error(err)
            })
    }
    // const rr2 = [
    //     {
    //         "count_cart": "1",
    //         "id": "278070",
    //     },
    //     {
    //         "count_cart": "1",
    //         "id": "92760",
    //     }
    // ]

    return (
        <main className='page-cart'>
            {
                isLoading ?
                    <Container>
                        <div className="cart-empty">
                            <h3 className='text-left'>Корзина</h3>
                            <p>Загрузка...</p>
                        </div>
                    </Container>
                    :
                    <>{
                        products.length === 0 &&
                        <Container>
                            <div className="cart-empty">
                                <h3>Корзина пуста</h3>
                                <NavLink to="/" className="btn btn-primary">Вернуться на главную</NavLink>
                                {/* <button
                                    onClick={() => {
                                        localStorage.setItem("galaktika-cart", JSON.stringify(rr2))
                                    }}
                                >
                                    Заполнить корзину 2 товарами
                                </button> */}
                            </div>
                        </Container>
                    }

                        {
                            products.length > 0 &&
                            <Container>
                                <h3>Корзина</h3>

                                {
                                    textAlert?.text &&
                                    <AlertCart data={textAlert} />
                                }
                                <div className="page-cart-container">
                                    <div className="cart-items">
                                        <div className="cart-items__wrap">
                                            {
                                                isIterableArray(products) &&
                                                products.map((product, index) => (
                                                    <CartItem key={index} product={product} index={index} isControl={true} isDelete />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="page-cart-footer">
                                    <NavLink to="/" className='to-back'>
                                        <Button variant="link" className="open-cart" >Вернуться к покупкам</Button>
                                    </NavLink>
                                    <div className="page-cart-footer__content">
                                        <p>Стоимость без скидки: <span className="i-rub">{parseFloat((getTotalWithoutSale(products) * 1).toFixed(2)).toLocaleString('ru')}</span></p>
                                        <p>Скидка: <span className="i-rub text-sale">{parseFloat((getTotalWithoutSale(products) * 1).toFixed(2) - (getTotalSumm(products) * 1).toFixed(2)).toLocaleString('ru')}</span></p>
                                        <div className='page-cart-footer__content_btn'>
                                            <p>Итоговая стоимость: <span className="i-rub">{parseFloat((getTotalSumm(products) * 1).toFixed(2)).toLocaleString('ru')}</span></p>
                                            <NavLink to="/checkout" className="btn btn-primary">Оформить заказ</NavLink>
                                        </div>
                                    </div>
                                </div>

                                <SliderFetchWrapper
                                    urlFetch={`${process.env.REACT_APP_API_BASE}/pages/cart-slider`}
                                />

                            </Container>
                        }
                    </>
            }
        </main>
    )
}

export default PageCart