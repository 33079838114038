import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

const AlertCart = ({ data }) => {

    const [isShow, setIsShow] = useState(true);

    const getAlertVariant = (type) => {
        if (type === 1) return 'primary'
        if (type === 2) return 'success'
        if (type === 3) return 'warning'
        if (type === 4) return 'danger'
    }

    return (
        isShow ?
            <Alert variant={getAlertVariant(data.type)} onClose={() => setIsShow(false)} dismissible={data.isClose}>
                <div dangerouslySetInnerHTML={{ __html: data.text }} />
            </Alert>
            :
            <></>
    )
}

export default AlertCart