
// React 
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InputNumber from 'rc-input-number';

import CartCtx from '../CartCtx'
//SCSS
import "../../scss/components/pages/cart/cart.scss"
import CartItem from './CartItem';

class CartMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            product: {},
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }
    onChange = (e, id, count, articule) => {
        // console.log(e, id, count, articule)
        if (e < count) this.context.decreaseProduct(id)
        if (e > count) this.context.increaseProduct(id)
    }
    static contextType = CartCtx;

    render() {

        return (
            <div className="modal-cart__wrap">

                <div className="cart-items">
                    <div className="cart-items__wrap"> 
                        {this.context.cart.map((product, index) => (
                            <CartItem key={index} product={product} index={index} isControl={this.props.isControl} />
                        ))}
                    </div>
                </div>

                {/* <div className="cart-footer">
                    <div className="cart-footer__left">
                    </div>
                    <div className="cart-footer__right">
                        <div className="cart-checkout">
                            {this.context.getTotalCount() > 0 &&
                                <Link to="/PageCheckout" className="btn btn-primary d-none">Оформить заказ</Link>
                            }
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default CartMain;
